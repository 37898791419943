import RenderJSX from '../../../Lib/render-jsx';
import { Marker, divIcon, DomUtil, GeoJSON } from 'leaflet';

import './_marker.scss';

const LogoMarker = Marker.extend( {

	options: {
		icon: false,
		zIndexOffset: 1000,
		clickable: true,
	},

	initialize( Tourguide, logo ) {
		this.Tourguide = Tourguide;
		this.data = logo;

		const options = {
			...this.options,
			'icon': this.getIcon(),
		};

		Marker.prototype.initialize.call( this, GeoJSON.coordsToLatLng( logo.position.coordinates ), options );
	},

	onAdd( map ) {
		return Marker.prototype.onAdd.call( this, map );
	},

	onRemove( map ) {
		return Marker.prototype.onRemove.call( this, map );
	},

	getIcon() {
		if ( this.data.typ === 'image' ) {
			return divIcon( {
				className: 'poi_logo_holder',
				html: this.renderIcon(),
				iconAnchor: [ -15, 15 ],
			} );
		}
		return divIcon( {
			className: 'label-box',
			html: this.renderIcon(),
			iconAnchor: [ -15, 15 ],
		} );
	},

	renderIcon() {
		const { title, typ, image_position: pos, link_destination: url } = this.data;
		const image = this.data.image || false;
		const iconUrl = this.Tourguide.getIcon( image );

		const element = DomUtil.create( 'div' );

		RenderJSX.render(
			<>
				{'image' === typ
					? (
						<a target="_blank" href={url}>
							<img class="poi_logo_holder" src={iconUrl} />
						</a>
					) : ''}

				{'label' === typ
					? (
						<div class="label-box small-text">
							<div class="box_text">{title}</div>
						</div>
					) : ''}

				{'both' === typ
					? (
						<a class="label-box small-text" href={url} >
							<img class={'img-' + pos} src={iconUrl} />
							<div class="box_text">{title}</div>
						</a>
					) : ''}
			</>,
		).on( element );

		return element;
	},
} );

export default LogoMarker;
