import { GridLayer } from 'leaflet';

// Load Sublibs.
import 'leaflet.gridlayer.googlemutant';
import * as Google from 'google-maps';

const buildGoogleMap = async ( map, options ) => {
	// Load Google Maps.
	const loader = new Google.Loader( process.env.GOOGLE_API_KEY );
	await loader.load();

	// Add Tile Layer.
	const layer = new GridLayer.googleMutant( options );
	layer.addTo( map );

	return layer;
};

export default buildGoogleMap;
