import { FeatureGroup } from 'leaflet';
import LogoMarker from './Marker';

const Logo = FeatureGroup.extend( {

	initialize( Tourguide, logo ) {
		this.Tourguide = Tourguide;
		this.data = logo;

		this.marker = new LogoMarker( this.Tourguide, logo );
		this.getLatLng();

		return FeatureGroup.prototype.initialize.call( this, [ this.marker ] );
	},

	getLatLng() {
		return this.marker.getLatLng();
	},

	center() {
		this.Tourguide.animatedTransition( () => {
			this._map.setView( this.getLatLng(), this._map.getZoom(), { animate: false } );
		} );
	},
} );

export default Logo;
