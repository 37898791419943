import RenderJSX from '../../../Lib/render-jsx';
import { Marker, divIcon, DomUtil, GeoJSON } from 'leaflet';

import './_marker.scss';

const PoiMarker = Marker.extend( {

	options: {
		icon: false,
		zIndexOffset: 1000,
		clickable: true,
	},

	initialize( Tourguide, poi ) {
		this.Tourguide = Tourguide;
		this.data = poi;

		const options = {
			...this.options,
			'icon': this.getIcon(),
		};

		this.on( 'mouseover', this.onMouseOver, this );
		this.on( 'mouseout', this.onMouseOut, this );

		Marker.prototype.initialize.call( this, GeoJSON.coordsToLatLng( poi.position.coordinates ), options );
	},

	onAdd( map ) {
		return Marker.prototype.onAdd.call( this, map );
	},

	onRemove( map ) {
		return Marker.prototype.onRemove.call( this, map );
	},

	onMouseOver() {
		this.setZIndexOffset( 2000 );
	},

	onMouseOut() {
		this.setZIndexOffset( 1000 );
	},

	getIcon() {
		return divIcon( {

			className: 'poi-holder',
			html: this.renderIcon(),
			iconSize: [ 46, 46 ],
			iconAnchor: [ 23, 23 ],
		} );
	},

	renderIcon() {
		const { title } = this.data;
		const element = DomUtil.create( 'div', 'poi_holder' );
		const iconUrl = this.Tourguide.getIcon( this.data.category.icon );
		RenderJSX.render(
			<>
				<img class="poi_marker" src={iconUrl} />
				<div class="poi_blue_line">
					<div class="poi_popup">{title}</div>
				</div>
			</>,
		).on( element );

		return element;
	},
} );

export default PoiMarker;
