
import Icomoon from './../../Lib/Icomoon';

const 	LEVELS = [
	'overview',
	'country',
	'segment',
	'stage',
];

/* global __, _p */
const Specifications = function( props, RenderJSX ) {
	const { mode, data, 'class': className, spanClass, ...otherProps }  = props;

	const difficulties = {
		'1': __( 'Beginner' ),
		'2': __( 'Intermediate' ),
		'3': __( 'Advanced' ),
		'4': __( 'Expert' ),
		'5': __( 'Hannibal' ),
	};

	const nextMode = LEVELS[ LEVELS.indexOf( mode ) + 1 ] || false;

	const specifications = [
		data.children && nextMode ? {
			icon: Icomoon.sign,
			text: data.children.length + ' ' + Specifications.modeTitle( nextMode, data.children.length ),
		} : false,
		data.length ? {
			icon: Icomoon.length2,
			text: data.length + ' km',
		} : false,
		data.difficulty ? {
			icon: Icomoon.hearth,
			text: difficulties[ data.difficulty ],
		} : false,
		data.duration ? {
			icon: Icomoon.time,
			text: data.duration,
		} : false,
		data.altitude ? {
			icon: Icomoon.heightProfile,
			text: data.altitude + ' m',
		} : false,

	].filter( ( i ) => i && i.text );

	return (
		<div class={[ 'specifications' ].concat( className || [] )} {...otherProps}>
			{specifications.slice( 0, 4 ).map( ( spec ) => (
				<span class={[].concat( spanClass || [] )}><i class="icon" _content={spec.icon}></i> {spec.text}</span>
			) ) }
		</div>
	);
};

Specifications.modeTitle = ( mode, count = 1 ) => {
	const index = _p()( count );

	switch ( mode ) {
		case 'overview':
			return __( 'Route', 'Routes' )[ index ];

		case 'country':
			return __( 'Country', 'Countries' )[ index ];

		case 'segment':
			return __( 'Segment', 'Segments' )[ index ];

		case 'stage':
			return __( 'Stage', 'Stages' )[ index ];

		default:
			return '';
	}
};

export default Specifications;
