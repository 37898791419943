import L from './../../Lib/Leaflet';

import Mapbox from 'mapbox.js/src/mapbox.js';
import 'mapbox.js/dist/mapbox.standalone.css';

// Set Access Token.
L.mapbox.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

const buildMapboxMap = ( map, { url, type, ...options } ) => {
	if ( type ) {
		const layer = Mapbox.tileLayer( type, options );
		layer.addTo( map );
		return layer;
	} else if ( url ) {
		const layer = Mapbox.styleLayer( url, options );
		layer.addTo( map );
		return layer;
	}

	throw 'Undefined Mapbox TileLayer Type';
};

export default buildMapboxMap;
