// Load Leaflet first.
import './Lib/Leaflet';

// Load Styles.
import './index.scss';

import MapBuilder from './Map/Map';
import Tourguide from './Tourguide/Tourguide';
import styles from './styles.json';

const options = {};

const MapMaker = new MapBuilder( document.getElementById( 'tao-map' ), options );

const map = MapMaker.getMap();
const optionMap = {
	google: {
		type: 'roadmap',
		styles,
	},
	mapbox: {
		url: 'mapbox://styles/taosoftware/cl3ld7rq9004b14mls42ij1ow',
	},
	osm: {
		type: 'ocm-outdoor',
	},
};

//console.log( optionMap[ process.env.PROVIDER ] );
const TL = MapMaker.addTileLayer( optionMap[ process.env.PROVIDER ] );

const TaoTourguide = new Tourguide( map );

// Expose for testing
//console.log( process.env.NODE_ENV );
if ( 'development' === process.env.NODE_ENV ) {
	window.Tourguide = TaoTourguide;
	window.map = map;
	window.TL = TL;
}

TaoTourguide.addModule( 'Error' );
TaoTourguide.addModule( 'Title' );
TaoTourguide.addModule( 'Fullscreen' );
TaoTourguide.addModule( 'Navigation' );
TaoTourguide.addModule( 'Stages' );
TaoTourguide.addModule( 'Pois' );
TaoTourguide.addModule( 'Logos' );
TaoTourguide.addModule( 'Zoom' );

const initialURL = new URL( document.location.href );
const searchParams = initialURL.searchParams;

class Error404 {
	constructor( message ) {
		this.message = message;
	}
}

const awaitMap = () => {
	// Fix map initially not having any size.
	return new Promise( ( resolve ) => {
		const onLoad = () => {
			setTimeout( () => {
				map.invalidateSize();
				resolve();
			}, 10 );
		};

		if ( map._loaded ) {
			onLoad();
		} else {
			map.on( 'load', () => onLoad() );
		}
	} );
};

const loadTracks = () => {
	/**
	 * Single Load - Do not display data, other than the requested one.
	 */
	if ( searchParams.get( 'single' ) ) {
		if ( searchParams.get( 'country' ) ) {
			return Promise.all(
				[
					TaoTourguide.API.getCountry( searchParams.get( 'country' ) ),
				],
			).then( ( [ country ] ) => {
				return TaoTourguide.setView( country );
			} );
		}

		if ( searchParams.get( 'segment' ) ) {
			return Promise.all(
				[
					TaoTourguide.API.getSegment( searchParams.get( 'segment' ) ),
				],
			).then( ( [ segment ] ) => {
				return TaoTourguide.setView( segment );
			} );
		}

		if ( searchParams.get( 'stage' ) ) {
			return Promise.all(
				[
					TaoTourguide.API.getStage( searchParams.get( 'stage' ) ),
				],
			).then( ( [ stage ] ) => {
				return TaoTourguide.API.getSegment( stage.parent ).then( ( segment ) => {
					return TaoTourguide.setView( segment );
				} );
			} );
		}
	}

	/**
	 * Load all data.
	 */
	return Promise.all(
		[
			TaoTourguide.API.getOverview(),
			TaoTourguide.API.getCountries(),
		],
	).then( ( [ overview, countries ] ) => {
		overview.children = countries;
		return TaoTourguide.setView( overview );
	} );
};

const selectTrack = () => {
	const Stages = TaoTourguide.getModule( 'Stages' );
	let stage = false;

	// Activate specific stage, depending on URL Param.
	if ( searchParams.get( 'stage' ) ) {
		stage = Stages.findStage( 'stage', searchParams.get( 'stage' ) );
	} else if ( searchParams.get( 'segment' ) ) {
		stage = Stages.findStage( 'segment', searchParams.get( 'segment' ) );
	} else if ( searchParams.get( 'country' ) ) {
		stage = Stages.findStage( 'country', searchParams.get( 'country' ) );
	} else {
		stage = Stages.Collection;
	}

	if ( stage ) {
		stage.activate( true, false );
	} else {
		throw new Error404( 'Stage not found!' );
	}

	return stage;
};

const selectPoi = () => {
	const Pois   = TaoTourguide.getModule( 'Pois' );
	const Logos  = TaoTourguide.getModule( 'Logos' );
	if ( searchParams.get( 'poi' ) ) {
		const poiPin = Pois.findLayer( searchParams.get( 'poi' ) );

		if ( ! poiPin ) {
			throw new Error404( 'POI not found!' );
		}

		if ( ! poiPin._map ) {
			Pois.Collection.addLayer( poiPin );
		}

		poiPin.activate();
		poiPin.center();
	} else if ( searchParams.get( 'logo' ) ) {
		const logo = Logos.findLayer( searchParams.get( 'logo' ) );

		if ( ! logo ) {
			throw new Error404( 'Logo not found!' );
		}

		if ( ! logo._map ) {
			Logos.Collection.addLayer( logo );
		}
		logo.center();
	}
};

const init = async () => {
	await awaitMap();
	await loadTracks();
	await selectTrack();
	await selectPoi();
};

init().then( () => {
	document.body.classList.remove( 'loading' );
} ).catch( ( error ) => {
	// eslint-disable-next-line
	console.error( error );

	if ( error instanceof Error404 ) {
		TaoTourguide.getModule( 'Error' ).render404();
	} else {
		TaoTourguide.getModule( 'Error' ).render500();
	}
} );
