import { TileLayer } from 'leaflet';

const buildCustomMap = function( map, { url, ...options } ) {
	// Add Tile layer.
	const layer = new TileLayer( url, options );
	layer.addTo( map );

	return layer;
};

export default buildCustomMap;
