
import Carousel from 'bootstrap/js/src/carousel.js';

const Gallery = function( props, RenderJSX ) {
	const { id, 'class': className, images, imageArgs, withDescription, withCopyright, _ref: forwardRef, ...rest } = props;

	this.container = RenderJSX.ref();

	this.getCarousel = () => {
		return Carousel.getOrCreateInstance( this.container.$, { interval: 0 } );
	};

	this.onBind( () => {
		this.getCarousel();
	} );

	this.onClear( () => {
		this.getCarousel().dispose();
	} );

	if ( forwardRef ) {
		forwardRef.resolve( this );
	}

	const iArgs = imageArgs || {
		'thumb': '480x320',
		'crop': 1,
	};

	const res = (
		<div id={id} class={[ 'carousel', 'slide' ].concat( className || [] )} data-bs-ride="carousel" {...rest} _ref={this.container} >
			<div class="carousel-inner">
				{images.map( ( item, i ) => (
					<figure class={[ 'carousel-item' ].concat( 0 === i ? 'active' : [] )}>
						<img class="d-block w-100 h-auto" src={window.Tourguide.getImage( item, iArgs )} loading="lazy" draggable="false" width="480" height="320" />
						{( withDescription && item.description ) ? <figcaption class="d-block w-100 position-absolute">{item.description}</figcaption> : ''}
						{( withCopyright && item.copyright ) ? <small class="d-block w-100 position-absolute text-end copyright">&copy; {item.copyright}</small> : ''}
					</figure>
				) )}
			</div>
			{images.length > 1
				? ( <>
					<button class="carousel-control-prev" type="button" data-bs-slide="prev" onClick={() => this.getCarousel().prev()}>
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Previous</span>
					</button>
					<button class="carousel-control-next" type="button" data-bs-slide="next" onClick={() => this.getCarousel().next()}>
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Next</span>
					</button>
				</> ) : ''
			}
		</div>
	);

	return res;
};

export default Gallery;

