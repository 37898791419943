import { TileLayer } from 'leaflet';

const osmAttr = '&copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>';

const OpenBaseLayer = TileLayer.extend( {
	initialize( options ) {
		TileLayer.prototype.initialize.call( this, options.url || this.url, options );
	},
} );

const CloudMade = OpenBaseLayer.extend( {
	url: 'https://{s}.tile.cloudmade.com/{key}/{styleId}/256/{z}/{x}/{y}.png',
	options: {
		attribution: 'Map data ' + osmAttr + ', Imagery &copy; <a href="https://cloudmade.com">CloudMade</a>',
		styleId: 997,
	},
} );

const OpenStreetMap = OpenBaseLayer.extend( {
	url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	options: {
		attribution: osmAttr,
	},
} );

const OpenCycleMap = OpenBaseLayer.extend( {
	url: 'https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png',
	options: {
		attribution: '&copy; OpenCycleMap, ' + 'Map data ' + osmAttr,
	},
} );

const OpenCycleMapLandscape = OpenBaseLayer.extend( {
	url: 'https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png',
	options: {
		attribution: '&copy; OpenCycleMap, ' + 'Map data ' + osmAttr,
		subdomains: [ 'a', 'b', 'c' ],
	},
} );

const OpenCycleMapOutdoor = OpenBaseLayer.extend( {
	url: 'https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png',
	options: {
		attribution: '&copy; OpenCycleMap, ' + 'Map data ' + osmAttr,
		subdomains: [ 'a', 'b', 'c' ],
	},
} );

const getLayer = ( { type, ...options } ) => {
	switch ( type ) {
		case 'cloudmade':
			return new CloudMade( options );

		case 'ocm':
		case 'opencyclemap':
			return new OpenCycleMap( options );

		case 'ocm-outdoor':
		case 'opencyclemap-outdoor':
			return new OpenCycleMapOutdoor( options );

		case 'ocm-landscape':
		case 'opencyclemap-landscape':
			return new OpenCycleMapLandscape( options );

		case 'osm':
		case 'openstreetmap':
		default:
			return new OpenStreetMap( options );
	}
};

const buildOpenStreetMap = function( map, options ) {
	const layer = getLayer( options );
	layer.addTo( map );
	return layer;
};

export default buildOpenStreetMap;
