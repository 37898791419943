
import Module from './../Module';

import Control from './Control';

import './fullscreen.scss';

export default class Navigation extends Module {
	/**
	 * Initialise Marker Element and add to map.
	 */
	onAdd = () => {
		// Add Control.
		this.Control = new Control( this.Tourguide, { 'position': 'topleft' } );
		this.Control.addTo( this.Tourguide.getMap() );
	};

	/**
	 * Remove Marker Element from map.
	 */
	onRemove = () => {
		// Remove Control.
		this.Control.remove( this.Tourguide.getMap() );
		delete this.Control;
	};

	openWith = ( gallery ) => {
		return new Promise( ( resolve ) => {
			const doResolve = () => {
				this.Tourguide.getMap().off( 'close.fullscreen', doResolve );
				resolve();
			};
			this.Tourguide.getMap().on( 'close.fullscreen', doResolve );

			// Render and Show Fullscreen Gallery.
			this.Control.render( gallery );
			this.Control.show();
		} );
	};
}
