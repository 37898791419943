
export default class Module {
	depends = [];

	constructor( Tourguide ) {
		this.Tourguide = Tourguide;
	}

	add = () => {
		this.Tourguide.addModule( this );
		return this;
	};

	remove = () => {
		this.Tourguide.removeModule( this );
		return this;
	};

	onAdd = () => {

	};

	onRemove = () => {

	};
}
