
import RenderJSX from '../../../Lib/render-jsx';
import Module from './../Module';

import { DomUtil } from 'leaflet';

import './_error.scss';

export default class ErrorModule extends Module {
	debug = false;

	onAdd = () => {
		this._container = DomUtil.create( 'div', 'tao-module--error no-propagation d-none', document.body );
		this.Tourguide.getMap().getContainer().after( this._container );

		return this;
	};

	onRemove = () => {
		this._container.remove();

		return this;
	};

	show = () => {
		return DomUtil.removeClass( this._container, 'd-none' );
	};

	hide = () => {
		return DomUtil.addClass( this._container, 'd-none' );
	};

	clean = () => {
		if ( this._container.firstChild ) {
			RenderJSX.remove( this._container.firstChild );
			this._container.innerHTML = '';
		}
	};

	render404() {
		this.clean();
		RenderJSX.render(
			<div>
				<h1>404 - Keine Daten gefunden</h1>
			</div>,
		).on( this._container );

		this.show();
	}

	render500() {
		this.clean();
		RenderJSX.render(
			<div>
				<h1>Ein unerwarteter Fehler ist aufgetreten</h1>
			</div>,
		).on( this._container );
		this.show();
	}
}
