
import Module from './../Module';

import ZoomControl from './ZoomControl';

import './_zoom.scss';

export default class Stages extends Module {
	debug = false;

	onAdd = () => {
		this.Control = new ZoomControl( this.Tourguide );
		this.Control.addTo( this.Tourguide.getMap() );

		this.show();

		//this.Tourguide.getMap().on( 'activate.tao', this.show, this );

		return this;
	};

	onRemove = () => {
		//this.Tourguide.getMap().off( 'activate.tao', this.show, this );
		//this.Tourguide.getMap().off( 'deactivate.tao', this.hide, this );

		this.Control.removeFrom( this.Tourguide.getMap() );
		this.Tourguide.getMap().remove( this.Control );

		return this;
	};

	show = () => {
		return this.Control.show();
	};

	hide = () => {
		return this.Control.hide();
	};
}
