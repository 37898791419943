
export { default as angleLeft } from './tg-angle-left.svg';
export { default as angleRight } from './tg-angle-right.svg';

export { default as chevronLeft } from './tg-chevron-left.svg';
export { default as chevronRight } from './tg-chevron-right.svg';

export { default as circle } from './tg-circle.svg';
export { default as close } from './tg-close.svg';
export { default as comment } from './tg-comment.svg';
export { default as compass } from './tg-compass.svg';

export { default as doc } from './tg-doc.svg';
export { default as doc2 } from './tg-doc2.svg';

export { default as doubleAngleDown } from './tg-double-angle-down.svg';
export { default as down } from './tg-down.svg';
export { default as dropdown } from './tg-dropdown.svg';

export { default as essen } from './tg-essen.svg';
export { default as expand } from './tg-expand.svg';

export { default as flag } from './tg-flag.svg';
export { default as flag2 } from './tg-flag2.svg';
export { default as flag3 } from './tg-flag3.svg';
export { default as fullscreen } from './tg-fullscreen.svg';
export { default as hearth } from './tg-hearth.svg';
export { default as heightProfile } from './tg-height-profile.svg';
export { default as height } from './tg-height.svg';
export { default as home } from './tg-home.svg';
export { default as home2 } from './tg-home2.svg';
export { default as length } from './tg-length.svg';
export { default as length2 } from './tg-length2.svg';
export { default as mapOutline } from './tg-map-outline.svg';
export { default as map } from './tg-map.svg';
export { default as minimize } from './tg-minimize.svg';
export { default as pin } from './tg-pin.svg';
export { default as sign } from './tg-sign.svg';
export { default as time } from './tg-time.svg';
export { default as tree } from './tg-tree.svg';
export { default as up } from './tg-up.svg';
export { default as users } from './tg-users.svg';
export { default as world } from './tg-world.svg';

export { default as faBullseye } from './fa-bullseye.svg';
export { default as faCircle } from './fa-circle.svg';
export { default as faMinus } from './fa-minus.svg';
export { default as faPlus } from './fa-plus.svg';
