
export { default as Title } from './Title/Title.js';

export { default as Stages } from './Stages/Stages.js';

export { default as Pois } from './Pois/Pois.js';

export { default as Logos } from './Logos/Logos.js';

export { default as Navigation } from './Navigation/Navigation.js';

export { default as Fullscreen } from './Fullscreen/Fullscreen.js';

export { default as Zoom } from './Zoom/Zoom.js';

export { default as Error } from './Error/Error.js';
