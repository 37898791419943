
import { FeatureGroup, GeoJSON, Point } from 'leaflet';
import StageLine from './Line';
import StageMarker from './Marker';

const getLatLngs = ( stage ) => {
	const lnglats = stage?.path?.coordinates;

	if ( ! lnglats && stage.children && stage.children.length > 0 ) {
		return stage.children.map( ( child ) => {
			return getLatLngs( child );
		} );
	}

	return GeoJSON.coordsToLatLngs( lnglats ) || [];
};

const StageData = {

	getPrev() {
		if ( this.data.prev ) {
			return this.Stages.findStage( this.data.layer, this.data.prev );
		}

		const Parent = this.getParent();
		return Parent && Parent.getPrev();
	},
	getNext() {
		if ( this.data.next ) {
			return this.Stages.findStage( this.data.layer, this.data.next );
		}

		const Parent = this.getParent();
		return Parent && Parent.getNext();
	},

	getChildren() {
		return this.children || [];
	},

	getParent() {
		return this.parent;
	},

	getParents() {
		const ancestors = [];
		let parent = this;

		while ( parent ) {
			ancestors.push( parent );
			parent = parent.getParent();
		}

		return ancestors;
	},

	getProgenitor() {
		const parents = this.getParents();
		return parents[ parents.length - 1 ];
	},

	hasChildren() {
		return this.getChildren().length > 0;
	},

	getAllStages() {
		if ( ! this.hasChildren() ) {
			return [ this ];
		}

		return this.children.reduce( ( carry, child ) => {
			return carry.concat( child.getAllStages() || [] );
		}, [ this ] );
	},

	findStage( level, id ) {
		return this.getAllStages().find( ( layer ) => {
			if ( level !== layer.data.layer ) {
				return false;
			}

			if ( parseInt( layer.data.id ) === parseInt( id ) ) {
				return true;
			}

			if ( layer.data.slug === id ) {
				return true;
			}

			return false;
		} );
	},

	getActive() {
		return this.getAllStages().find( ( layer ) => layer.active );
	},

};

const Stage = FeatureGroup.extend( {

	includes: [ StageData ],

	initialize( data, Stages, parent ) {
		const latlngs = getLatLngs( data );

		this.data = data;
		this.line = new StageLine( latlngs, this );

		this.Stages = Stages;
		this.Tourguide = Stages.Tourguide;

		this.elements = [];
		this.children = [];
		this.parent = parent;

		this.elements = [ this.line ].concat( StageMarker.getMarkers( latlngs, this ) );

		if ( data.children && data.children.length > 0 ) {
			this.children = data.children.map( ( child ) => {
				return new Stage( child, Stages, this );
			} );
		}

		return FeatureGroup.prototype.initialize.call( this, this.getLayersForMode( this.mode ) );
	},

	is( level ) {
		return level === this.data.layer;
	},

	mode: 'elements',
	changeMode( mode ) {
		const modes = [ 'children', 'elements' ];

		// Invalid Call.
		if ( ! modes.includes( mode ) || mode === this.mode ) {
			return false;
		}

		this.mode = mode;
		this.clearLayers();
		this.getLayersForMode( mode ).forEach( ( layer ) => this.addLayer( layer ) );
	},

	getLayersForMode( mode ) {
		if ( ! this.hasChildren() ) {
			return this.elements;
		}

		return 'children' === mode ? this.children : this.elements;
	},

	setLayerModeChildren() {
		// Buble upwards.
		const parent = this.getParent();
		if ( parent ) {
			parent.setLayerModeChildren();
		}

		this.getChildren().forEach( ( child ) => {
			child.changeMode( 'elements' );
		} );
		this.changeMode( 'children' );
	},

	fitBounds( options = {} ) {
		const map = this._map;
		const bounds = this.getBounds();

		const padTL = new Point( 0, map.getSize().y / 20 );
		const padBR = new Point( map.getSize().x / 2, map.getSize().y / 20 );

		if ( this.hasChildren() ) {
			map.fitBounds( bounds, {
				animate: true,
				...options,
			} );
		} else {
			map.fitBounds( bounds, {
				paddingTopLeft: padTL,
				paddingBottomRight: padBR,
				animate: true,
				...options,
			} );
		}
	},

	getBounds() {
		return this.line.getBounds();
	},

	getCenter() {
		return this.line.getCenter();
	},

	setStatus( status ) {
		return this.line.setStatus( status );
	},

	activate( centerStage = true, animate = true ) {
		this.fire( 'beforeactivate.tao', { layer: this }, true );
		this.setLayerModeChildren();

		if ( centerStage ) {
			this.Tourguide.animatedTransition( () => this.fitBounds( { animate } ) );
		}
		this.fire( 'activate.tao', { layer: this }, true );
	},

	deactivate() {
		this.fire( 'deactivate.tao', { layer: this }, true );
		this._map.fire( 'deactivate.tao', { layer: this }, true );
	},

	onActivate( ev ) {
		this.setStatus( 'active' );
		this.active = true;
	},

	onDeactivate() {
		this.active = false;
		this.setStatus( 'normal' );
	},

	onMouseEnter() {
		return this.setStatus( 'hover' );
	},

	onMouseOut() {
		return this.setStatus( 'normal' );
	},

} );

// The Top-Level Stage that handles a few special Events in addition to being a normal Stage.
Stage.Overview = Stage.extend( {

	mode: 'children',

	includes: [ StageData ],

	initialize( data, Stages ) {
		this.on( 'click', ( ev ) => this.onLayerClick( ev ), this );
		this.on( 'mouseover', ( ev ) => this.onLayerHover( ev ), this );

		this.on( 'activate.tao', ( ev ) => this.onLayerActivate( ev ), this );

		return Stage.prototype.initialize.call( this, data, Stages, null );
	},

	onLayerClick( ev ) {
		ev.sourceTarget.Stage.activate();
	},

	onLayerHover( ev ) {
		this.getAllStages().forEach( ( layer ) => {
			layer.onMouseOut();
		} );
		ev.sourceTarget.Stage.onMouseEnter();
	},

	onLayerActivate( ev ) {
		this.getAllStages().forEach( ( layer ) => {
			layer.onDeactivate();
		} );
		ev.sourceTarget.onActivate();

		if ( this._map ) {
			this._map.fire( 'activate.tao', { layer: ev.sourceTarget }, true );
		}
	},

} );

export default Stage;
