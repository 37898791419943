import Module from './../Module';

import { FeatureGroup } from 'leaflet';
import Poi from './Poi';

export default class Pois extends Module {
	constructor( Tourguide ) {
		super( Tourguide );
		this.Tourguide = Tourguide;
		this.Tourguide.Pois = this;

		// Set Collection.
		this.Collection = new FeatureGroup();
		this.Collection.pois = [];

		// Preload POI Data.
		this.getPois();
	}

	setCollection = async ( data ) => {
		return await this.getPois().then( ( pois ) => {
			const layers = pois.map( ( poi ) => new Poi( this.Tourguide, poi ) );

			this.Collection.pois = layers;
			this.setVisiblePois( data.pois );

			return this;
		} );
	};

	onAdd = () => {
		this.Collection.addTo( this.Tourguide.getMap() );
		this.Tourguide.getMap().on( 'activate.tao', this.onStageActivation, this );
		return this;
	};

	onRemove = () => {
		this.Tourguide.getMap().off( 'activate.tao', this.onStageActivation, this );
		this.Collection.removeFrom( this.Tourguide.getMap() );

		return this;
	};

	onStageActivation = ( ev ) => {
		const { layer } = ev;
		this.setVisiblePois( layer.data.pois );
	};

	setVisiblePois( pois ) {
		this.Collection.clearLayers();
		this.Collection.pois.filter( ( poi ) => pois.includes( parseInt( poi.data.id ) ) ).forEach( ( layer ) => this.Collection.addLayer( layer ) );
	}

	promise = false;
	getPois = () => {
		if ( this.promise ) {
			return this.promise;
		}

		this.promise = this.Tourguide.API.getPois();
		return this.promise;
	};

	findLayer = ( id ) => {
		return Object.values( this.Collection.pois ).find( ( poi ) => parseInt( poi.data.id ) === parseInt( id ) || poi.data.slug === id );
	};
}
