
import RenderJSX from '../../../Lib/render-jsx';

import { latLng as toLatLng, latLngBounds as toLatLngBounds, Marker, DivIcon, rectangle, GeoJSON } from 'leaflet';

import Module from './../Module';
import './Title.scss';

import { Specifications } from './../../Components';

export default class Title extends Module {
	debug = false;

	/*
	 * Initialise Marker Element and add to map.
	 */
	setData = ( data ) => {
		if ( this.marker ) {
			this.marker.removeFrom( this.Tourguide.getMap() );
		}

		this.marker = this.getMarker( data );
		if ( this.marker ) {
			this.marker.addTo( this.Tourguide.getMap() );
		}

		return this;
	};

	/**
	 * Add Marker Element Events to map.
	 */
	onAdd = () => {
		this.Tourguide.getMap().on( 'activate.tao', this.onActivate, this );
	};

	/**
	 * Remove Marker Element from map.
	 */
	onRemove = () => {
		this.Tourguide.getMap().off( 'activate.tao', this.onActivate, this );

		if ( this.marker ) {
			this.marker.removeFrom( this.Tourguide.getMap() );
			delete this.marker;
		}
	};

	onActivate = ( ev ) => {
		const { layer } = ev;
		if ( ! layer.is( 'stage' ) ) {
			this.setData( layer.data );
		}
	};

	getLatLng = ( track ) => {
		try {
			// When we have a latlng given, we use that one.
			const latlng = toLatLng( track.position_headline );
			if ( ! latlng ) {
				throw 'Invalid LatLng!';
			}

			return latlng;
		} catch ( e ) {
			// Else we do a whole bunch of calculations to find some free space.
			const latLngBounds = ( () => {
				const children = track.children && track.children.length > 0 ? track.children : [ track ];

				return children.map( ( { path } ) => {
					const latlngs = GeoJSON.coordsToLatLngs( path.coordinates );

					const bounds = toLatLngBounds( latlngs[ 0 ] );

					latlngs.forEach( ( latlng ) => {
						bounds.extend( latlng );
					} );

					if ( this.debug ) {
						rectangle( bounds.pad( .25 ), { color: '#0000FF' } ).addTo( this.Tourguide.getMap() );
					}
					return bounds.pad( .25 );
				} );
			} )();

			// Get Current Map.
			const map = this.Tourguide.getMap();

			// Get Center and Zoom for the Map after a theoretical call to fitbounds.
			const { center, zoom } = map._getBoundsCenterZoom( latLngBounds );

			const size  = map.getSize();
			const point = map.project( center, zoom );

			// Get Boundingbox of whole screen, after we call fitBounds.
			const mapBounds = [
				[ point.x - ( size.x / 2 ), point.y - ( size.y / 2 ) ],
				[ point.x + ( size.x / 2 ), point.y + ( size.y / 2 ) ],
			]
				.map( ( pixel ) => map.unproject( pixel, zoom ) )
				.reduce( ( prev, curr ) => prev.extend( curr ), toLatLngBounds() );

			// All Corners, where we could place the Title Module.
			const corners = [
				[ center, 1, 1 ],
				[ center, -1, -1 ],
				[ center, -1, 1 ],
				[ center, 1, -1 ],

				[ [ center.lat, mapBounds.getEast() ], -1, 1 ],
				[ [ center.lat, mapBounds.getWest() ], 1, 1 ],

				[ mapBounds.getSouthEast(), -1, -1 ],
				[ mapBounds.getSouthWest(), 1, -1 ],
				[ mapBounds.getNorthEast(), -1, 1 ],
				[ mapBounds.getNorthWest(), 1, 1 ],

				[ mapBounds.getSouthEast(), 1, 1 ],
				[ mapBounds.getSouthWest(), -1, 1 ],
				[ mapBounds.getNorthEast(), 1, -1 ],
				[ mapBounds.getNorthWest(), -1, -1 ],
			];

			if ( this.debug ) {
				rectangle( mapBounds, { color: '#ff0000' } ).addTo( map );
			}

			// Check all given Corners and place the Title Element in the first one, that does not intersect with our Stages.
			const found = corners.reduce( ( carry, [ corner, dirX, dirY ] ) => {
				if ( carry ) {
					return carry;
				}

				const corner1 = map.unproject( map.project( corner, zoom ).add( [ 40 * dirX, 40 * dirY ] ), zoom );
				const northWest = map.unproject( map.project( corner1, zoom ).add( [ 500 * dirX, 100 * dirY ] ), zoom );

				const container = toLatLngBounds( corner1, northWest );

				if ( this.debug ) {
					rectangle( container, { color: '#00ff00' } ).addTo( map );
				}

				const isContained = latLngBounds.reduce( ( prev, bounds ) => prev || bounds.intersects( container ), false );
				if ( ! isContained ) {
					return container.getNorthWest();
				}

				return false;
			}, false );

			if ( ! found ) {
				return [ NaN, NaN ];
			}

			return found;
		}
	};

	/**
	 * Crete Leaflet Marker Element with data from Tourguide.
	 *
	 * @param {Object} data
	 * @return {Marker} Leaflet Markerelement.
	 */
	getMarker = ( data ) => {
		const latlng = this.getLatLng( data );

		try {
			const marker = new Marker( toLatLng( latlng ), {
				icon: new DivIcon( {
					className: 'tao-module--title',
					html: this.render( data ),
					iconSize: [ 0, 0 ],
				} ),
				keyboard: false,
				zIndexOffset: 1500,
			} );

			marker.on( 'click', () => {
				this.Tourguide.reset();
			} );

			return marker;
		} catch ( error ) {
			// eslint-disable-next-line
			console.error( 'Error while adding Title Module.', error );
		}

		return null;
	};

	/**
	 * Renders the HTML for the Title Icon.
	 *
	 * @param {Object} data
	 * @return {HTMLElement} Title Icon HTML rendered via JSX.
	 */
	render = ( data ) => {
		const { title, subline, ...track } = data;
		return (
			<div class="wrapper">
				{title ? ( <h1>{title}</h1> ) : ''}
				{subline ? ( <h2>{subline}</h2> ) : ''}

				<Specifications type="track" data={track} mode={track.layer} />
			</div>
		);
	};
}

