import Module from './../Module';

import { FeatureGroup } from 'leaflet';
import Logo from './Logo';

export default class Logos extends Module {
	constructor( Tourguide ) {
		super( Tourguide );
		this.Tourguide = Tourguide;
		this.Tourguide.Logos = this;

		// Set Collection.
		this.Collection = new FeatureGroup();
		this.Collection.pois = [];

		// Preload POI Data.
		this.getLogos();
	}

	setCollection = async ( data ) => {
		return await this.getLogos().then( ( logos ) => {
			const layers = logos.map( ( logo ) => new Logo( this.Tourguide, logo ) );

			this.Collection.logos = layers;
			this.setVisibleLogos( data.logos );

			return this;
		} );
	};

	onAdd = () => {
		this.Collection.addTo( this.Tourguide.getMap() );
		this.Tourguide.getMap().on( 'activate.tao', this.onStageActivation, this );
		return this;
	};

	onRemove = () => {
		this.Tourguide.getMap().off( 'activate.tao', this.onStageActivation, this );
		this.Collection.removeFrom( this.Tourguide.getMap() );

		return this;
	};

	onStageActivation = ( ev ) => {
		const { layer } = ev;
		this.setVisibleLogos( layer.data.logos );
	};

	setVisibleLogos( logos ) {
		this.Collection.clearLayers();
		this.Collection.logos.filter( ( logo ) => logos.includes( parseInt( logo.data.id ) ) ).forEach( ( layer ) => this.Collection.addLayer( layer ) );
	}

	promise = false;
	getLogos = async () => {
		if ( this.promise ) {
			return this.promise;
		}

		this.promise = this.Tourguide.API.getLogos();
		return this.promise;
	};

	findLayer = ( id ) => {
		return Object.values( this.Collection.logos ).find( ( logo ) => parseInt( logo.data.id ) === parseInt( id ) );
	};
}
