
import { DomUtil, DomEvent } from 'leaflet';

function Deferred() {
	const self = this;
	this.promise = new Promise( function( resolve, reject ) {
		self.reject = reject;
		self.resolve = resolve;
	} );
}

const OpenAndCloseMixin = {

	options: {
		'toggleClass': 'open',
	},

	animation: false,

	isAnimating() {
		return !! this.animation;
	},

	async cancel() {
		const anim = this.animation;
		this.animation.reject();
		this.animation = false;

		return anim;
	},

	startTransition( callback, maxDuration = 1000 ) {
		if ( this.animation ) {
			return this.animation;
		}

		// Do not run same transition resolver twice.
		this.animation = new Deferred();

		const onTransitionEnd = () => {
			// When Animation is false, that means the promise already resolved.
			if ( ! this.animation ) {
				return;
			}

			// Remove saved promise.
			DomEvent.off( this._container, 'transitionend', onTransitionEnd );
			this.animation.resolve( true );
			this.animation = false;
		};

		DomEvent.on( this._container, 'transitionend', onTransitionEnd );

		// Fallback in case the transition does not end as expected.
		clearTimeout( this.timeout );
		this.timeout = setTimeout( onTransitionEnd, maxDuration );

		// Start whatever would set off the transition.
		callback();

		return this.animation.promise;
	},

	isOpen() {
		return DomUtil.hasClass( this._container, this.options.toggleClass );
	},

	async open() {
		// Animation is currently happening - we cancel.
		if ( this.isAnimating() ) {
			await this.cancel();
		}

		// Already open.
		if ( this.isOpen() ) {
			return true;
		}

		return await this.startTransition( () => DomUtil.addClass( this._container, this.options.toggleClass ) ).catch( () => {
			DomUtil.removeClass( this._container, this.options.toggleClass );
			throw 'Cancelled';
		} );
	},

	async close() {
		// Animation is currently happening - we cancel.
		if ( this.isAnimating() ) {
			await this.cancel();
		}

		// Already closed.
		if ( ! this.isOpen() ) {
			return true;
		}

		return await this.startTransition( () => DomUtil.removeClass( this._container, this.options.toggleClass ) ).catch( () => {
			DomUtil.addClass( this._container, this.options.toggleClass );
			throw 'Cancelled';
		} );
	},

	async toggle() {
		return await ( this.isOpen() ? this.close() : this.open() );
	},

	async show() {
		DomUtil.removeClass( this._container, 'd-none' );
		return await new Promise( ( resolve ) => setTimeout( () => resolve(), 0 ) );
	},

	async hide() {
		DomUtil.addClass( this._container, 'd-none' );
		DomUtil.removeClass( this._container, this.options.toggleClass );
		return await new Promise( ( resolve ) => setTimeout( () => resolve(), 0 ) );
	},
};

export default OpenAndCloseMixin;
