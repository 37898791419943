
import { FeatureGroup } from 'leaflet';
import PoiMarker from './Marker';

const Poi = FeatureGroup.extend( {

	initialize( Tourguide, poi ) {
		this.Tourguide = Tourguide;
		this.data = poi;

		this.marker = new PoiMarker( this.Tourguide, poi );

		this.on( 'click', () => this.activate(), this );
		this.getLatLng();

		return FeatureGroup.prototype.initialize.call( this, [ this.marker ] );
	},

	getLatLng() {
		return this.marker.getLatLng();
	},

	activate() {
		this.fire( 'poi-activate.tao', { layer: this }, true );
		this._map.fire( 'poi-activate.tao', { layer: this }, true );
	},

	deactivate() {
		this.fire( 'deactivate.tao', { layer: this }, true );
		this._map.fire( 'deactivate.tao', { layer: this }, true );
	},

	center() {
		this.Tourguide.animatedTransition( () => {
			this._map.setView( this.getLatLng(), this._map.getZoom(), { animate: false } );
		} );
	},
} );

export default Poi;
