
import Module from './../Module';

import Stage from './Stage';
import { Browser, latLngBounds, rectangle, circle } from 'leaflet';

export default class Stages extends Module {
	debug = false;

	constructor( Tourguide ) {
		super( Tourguide );
		this.Tourguide = Tourguide;
		this.Tourguide.Stages = this;
	}

	setCollection = ( data ) => {
		// Clear Collection and add new.
		if ( this.Collection ) {
			this.Collection.removeFrom( this.Tourguide.getMap() );
		}

		const Collection = new Stage.Overview( data, this );
		this.Tourguide.getMap().addLayer( Collection );
		this.Collection = Collection;

		if ( this.debug ) {
			Collection.getAllStages().forEach( ( layer ) => {
				const colors = {
					'overview': '#fff',
					'country': '#f00',
					'segment': '#0f0',
					'stage': '#00f',
				};

				rectangle( layer.getBounds(), { 'color': colors[ layer.data.layer ] } ).addTo( this.Tourguide.getMap() );
				circle( layer.getBounds().getCenter(), { 'color': colors[ layer.data.layer ] } ).addTo( this.Tourguide.getMap() );
			} );
		}

		return this;
	};

	onAdd = () => {
		this.Tourguide.getMap().on( 'reset.tao', this.onReset, this );
		this.Tourguide.getMap().on( 'dragend keypanend zoomend', this.onDragEnd, this );

		if ( ! Browser.mobile ) {
			this.Tourguide.getMap().on( 'drag', this.onDrag, this );
		}

		return this;
	};

	onRemove = () => {
		this.Tourguide.getMap().off( 'reset.tao', this.onReset, this );
		this.Tourguide.getMap().off( 'dragend keypanend zoomend', this.onDragEnd, this );
		this.Tourguide.getMap().off( 'drag', this.onDrag, this );
		return this;
	};

	onReset = () => {
		// Go one Layer up.
		this.Collection?.getActive()?.getParent()?.activate( true );
	};

	// Force Redraw on every dragstep
	// @see https://stackoverflow.com/questions/31327065/how-to-keep-out-of-viewport-leaflet-polylines-rendered-at-all-times
	onDrag = ( ev ) => {
		const map = ev.target;
		map?._renderer?._update();
	};

	onDragEnd = ( ev ) => {
		const map = ev.target;

		// Controlled Transition, e.g. on Stage Activation - do not run.
		if ( this.Tourguide.isAnimating() ) {
			return;
		}

		const Collection = this.Collection;
		const mapBounds = map.getBounds();

		// Sort all layers by their level.
		const layerMap = {};
		Collection.getAllStages().forEach( ( layer ) => {
			const level = layer.data.layer;
			layerMap[ level ] = ( layerMap[ level ] || [] ).concat( layer );
		} );

		const levels = Object.keys( layerMap );

		// Go through all layers in descending order.
		levels.every( ( level ) => {
			// Only show Layers that fit into current bounding box.
			const layers = layerMap[ level ].filter( ( layer ) => {
				const bounds = layer.getBounds();
				const zoom = map.getBoundsZoom( bounds );

				const isLowestLevel = level === levels[ levels.length - 1 ];
				const fitsInZoomLvl = map.getZoom() <= zoom;
				const intersectsNow = mapBounds.intersects( bounds );

				return ( fitsInZoomLvl || isLowestLevel ) && intersectsNow;
			} );

			let distance = false;
			let selected = null;

			// Find closest layer.
			layers.forEach( ( layer ) => {
				if ( mapBounds.intersects( layer.getBounds() ) ) {
					const center = mapBounds.getCenter();
					const poly = layer.getBounds().getCenter();

					const diff = center.distanceTo( poly );

					if ( ! selected || diff < distance ) {
						distance = diff;
						selected = layer;
					}
				}
			} );

			// We found a matching layer, time to finish.
			if ( selected ) {
				// Activate if not already active.
				if ( ! selected.active ) {
					map.stop();
					selected.activate( false );
				}

				// Found something, break out of every FN.
				return false;
			}

			// Continue.
			return true;
		} );
	};

	getActive = () => {
		return this.Collection.getActive() || this.Collection;
	};

	findStage = ( layer, id ) => {
		return this.Collection.findStage( layer, id );
	};

	getBounds = () => {
		return this.Collection.getBounds();
	};

	fitBounds = ( options = {} ) => {
		return this.Collection.fitBounds( options );
	};
}
