import Leaflet from '../Lib/Leaflet';

import Hooks from './../Lib/Hooks';
import TileLayers from './TileLayers';

import Mapbox from 'mapbox.js/src/mapbox.js';

const provider = process.env.PROVIDER;
const defaultOptions = {

	// Map defaults.
	center: [ 47.056714, 15.439503999999943 ],
	zoom: 13,
	zoomControl: false,
	scrollWheelZoom: false,
	doubleClickZoom: false,
	boxZoom: false,
	gestureHandling: true,

	// Mapbox defaults.
	tileLayer: false,
	featureLayer: false,
	gridLayer: false,
	legendControl: false,
	gridControl: false,

	// preferCanvas: true,
};

export default class MapBuilder {
	map = false;

	constructor( id, options = {} ) {
		// Merged options.
		this.options = Hooks.applyFilters(
			'map-options',
			{
				...defaultOptions,
				...options,
			},
			this,
		);

		this.element = id;
	}

	getMap = () => {
		if ( this.map ) {
			return this.map;
		}

		switch ( provider ) {
			case 'mapbox':

				// Custom Mapbox implementation.
				this.map = Mapbox.map( this.element, false, this.options );
				break;

			case 'google':
			default:

				this.map = Leaflet.map( this.element, this.options );
				break;
		}

		return this.map;
	};

	addTileLayer = ( options ) => {
		if ( TileLayers.hasProvider( provider ) ) {
			return TileLayers.getTileLayer( provider, this.map, options );
		}

		throw 'TileLayer of provider: ' + provider + ' not found! Please check environment vars!';
	};
}
