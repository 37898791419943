import * as Modules from './Modules';

import TourguideAPI from './API';

export default class Tourguide {
	Modules = {};

	constructor( map, data ) {
		this.map = map;
		this.data = data;

		this.API = new TourguideAPI();
	}

	/**
	 * Add a module to the Tourguide.
	 *
	 * @param {string} name Name of the Module.
	 * @return {Object}     Instance of the added Module.
	 */
	addModule = ( name ) => {
		this.removeModule( name );
		if ( 'undefined' !== typeof this.Modules[ name ] ) {
			return this.Modules[ name ];
		}

		// Create Module and call onAdd.
		const NewModule = this.Modules[ name ] = new Modules[ name ]( this );

		if ( 'function' === typeof NewModule.onAdd ) {
			NewModule.onAdd();
		}

		return NewModule;
	};

	/**
	 * Remove a previously added module and all its functionality from Tourguide.
	 *
	 * @param {string} name Name of the Module.
	 * @return {boolean} Whether removal was successful.
	 */
	removeModule = ( name ) => {
		if ( 'undefined' !== typeof this.Modules[ name ] ) {
			if ( 'function' === typeof this.Modules[ name ].onRemove ) {
				this.Modules[ name ].onRemove();
			}

			this.Modules[ name ].remove();
			delete this.Modules[ name ];
			return true;
		}

		return false;
	};

	hasModule = ( name ) => {
		return Object.keys( this.Modules ).includes( name );
	};

	getModule = ( name ) => {
		return this.Modules[ name ];
	};

	setData = ( data ) => {
		this.data = data;
	};

	setView = async ( data ) => {
		const promises = [];

		if ( this.hasModule( 'Stages' ) ) {
			promises.push(
				this.getModule( 'Stages' ).setCollection( data ),
			);
		}

		if ( this.hasModule( 'Pois' ) ) {
			promises.push(
				this.getModule( 'Pois' ).setCollection( data ),
			);
		}

		if ( this.hasModule( 'Logos' ) ) {
			promises.push(
				this.getModule( 'Logos' ).setCollection( data ),
			);
		}

		if ( this.hasModule( 'Title' ) ) {
			promises.push(
				this.getModule( 'Title' ).setData( data ),
			);
		}

		return Promise.all( promises );
	};

	getMap = () => {
		return this.map;
	};

	getData = () => {
		return this.data;
	};

	getTrack = () => {
		return this.data;
	};

	getStages = () => {
		return this.data.children;
	};

	getPois = () => {
		return this.data.pois;
	};

	getLogos = () => {
		return this.data.logos;
	};

	getIcon = ( icon ) => {
		const domain = process.env.DOMAIN || document.location.origin;
		const url = new URL( domain + `/uploads/${ icon.filename }` );

		return url.toString();
	};

	getImage = ( item, args ) => {
		const domain = process.env.DOMAIN || document.location.origin;

		const url = new URL( domain + `/asset/image/${ item.id }/file/` );

		for ( const name in args ) {
			url.searchParams.set( name, args[ name ] );
		}

		return url.toString();
	};

	getActive = () => {
		return this.getModule( 'Stages' ).getActive();
	};

	fullscreen = ( gallery ) => {
		return this.getModule( 'Fullscreen' ).openWith( gallery );
	};

	reset = () => {
		this.getMap().fire( 'reset.tao' );
	};

	/**
	 * Handle Animations.
	 */
	animating = false;

	isAnimating = () => {
		return this.animating;
	};

	setAnimating = ( status ) => {
		this.animating = status;
	};

	animatedTransition = ( callback ) => {
		this.setAnimating( true );
		this.getMap().once( 'moveend', () => this.setAnimating( false ) );
		callback();
	};
}
