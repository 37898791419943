
import RenderJSX from '../../../Lib/render-jsx';
import Icomoon from '../../../Lib/Icomoon';

import { DomUtil, Marker, Popup, divIcon, DomEvent } from 'leaflet';

import { Gallery, Specifications } from '../../Components';

import './_line.scss';
import './_marker.scss';
import './_popup.scss';

const StageMarker = Marker.extend( {

	options: {
		icon: false,
		zIndexOffset: 1000,
		clickable: true,
	},

	initialize( latlng, Stage ) {
		this.Stage = Stage;

		const options = {
			...this.options,
			'icon': this.generateIcon(),
		};

		Marker.prototype.initialize.call( this, latlng, options );

		this.on( 'keydown', this.onKeyDown, this );
		this.on( 'mouseover', this.onMouseOver, this );

		this.on( 'popupopen', this.onPopupOpen, this );
		this.on( 'popupclose', this.onPopupClose, this );

		this.setPopup();
	},

	toggle( force ) {
		const icon = this.getElement();
		if ( icon ) {
			return icon.classList.toggle( 'open', force );
		}
		return null;
	},

	open() {
		this._map.fire( 'openmarker.tao', { layer: this } );
		this.setZIndexOffset( 3000 );

		this.focus();
		return this.toggle( true );
	},

	close() {
		this._map.fire( 'closemarker.tao', { layer: this } );
		this.setZIndexOffset( 1000 );

		this.blur();
		return this.toggle( false );
	},

	load() {
		const icon = this.getElement();
		if ( icon ) {
			icon.classList.add( 'loaded' );
		}
		return true;
	},

	focus() {
		this.getElement().focus();
	},

	blur() {
		this._map.getContainer().focus();
	},

	onClick( ev ) {
		ev.stopPropagation();

		const icon = this.getElement();
		if ( icon.classList.contains( 'open' ) ) {
			return;
		}

		return this.open() && this.load();
	},

	onKeyDown( ev ) {
		switch ( ev.originalEvent.keyCode ) {
			case 27:
				this.close();
				return;
		}

		if ( this.gallery._resolved ) {
			const Carousel = this.gallery.$.getCarousel();
			Carousel._keydown( ev.originalEvent );
		}
	},

	onMouseOver( ev ) {
		const active = this.Stage.getProgenitor().getActive();

		// Don't open Popup when stage layer is active.
		if ( 'stage' !== active?.data?.layer ) {
			this.openPopup();
		}
	},

	onPopupOpen() {
		this.setZIndexOffset( 3000 );
	},

	onPopupClose( ) {
		this.setZIndexOffset( 1000 );
	},

	onAdd( map ) {
		// Add close Event to map.
		map.on( 'click openmarker.tao reset.tao', this.close, this );
		map.on( 'activate.tao', this.closePopup, this );

		return Marker.prototype.onAdd.call( this, map );
	},

	onRemove( map ) {
		// Remove close Event from map.
		map.off( 'click openmarker.tao reset.tao', this.close, this );
		map.off( 'activate.tao', this.closePopup, this );

		return Marker.prototype.onRemove.call( this, map );
	},

	openFullScreen( gallery ) {
		const Tourguide = this.Stage.Tourguide;
		Tourguide.fullscreen( gallery ).then( () => {
			this.focus();
		} );
	},

	generateIcon() {
		return divIcon( {

			className: 'marker-stage',
			html: this.renderIcon(),
			iconSize: [ 30, 30 ],
			popupAnchor: [ 32, -8 ],
		} );
	},

	renderIcon() {
		const Tourguide = this.Stage.Tourguide;

		const { title, slug, typ, gallery, sequence_id: point } = this.Stage.data;
		const image = this.Stage.data.image || ( this.Stage.data.gallery && this.Stage.data.gallery[ 0 ] ) || false;

		const element = DomUtil.create( 'div', 'tao-marker' );
		this.gallery = RenderJSX.ref();

		RenderJSX.render(
			<>
				{image ? (
					<div class="hidden_event_tracker" style="height:60px;width:60px;position:absolute;left:0px;">
						<div class="marker-mini-overlay" onClick={( ev ) => window.innerWidth > 678 && this.onClick( ev )} onMousedown={DomEvent.stopPropagation} onTouchStart={DomEvent.stopPropagation} >

							<div class="preview">
								<img src={Tourguide.getImage( image, { 'thumb': '60x40', 'crop': 1 } )} loading="lazy" draggable="false" />
								<div class="rsPreloader"></div>
							</div>

							<div class="slider-container">

								<Gallery id={`stage-gallery--${ slug }`} class={[ 'w-100', 'h-100' ]} images={gallery} _ref={this.gallery} data-bs-interval="0" />
								<div class="marker-overlay-title text-secondary">
									{title}
									{Tourguide.hasModule( 'Fullscreen' ) ? ( <span class="fullscreen" _content={Icomoon.fullscreen} onClick={() => this.openFullScreen( gallery )}></span> ) : ''}
								</div>
							</div>
						</div>
					</div>
				) : ''}

				{'start' === typ
					? (
						<div class="position-relative">
							<div class="pin">{Icomoon.pin.toSvgElement()}</div>
							<div class="flag">{Icomoon.flag.toSvgElement()}</div>
						</div>
					) : ( <div class="marker-icon">{point}</div> )
				}

			</>,
		).on( element );

		return element;
	},

	setPopup() {
		const { title, sequence_id: point, layer: mode } = this.Stage.data;

		const popup = new Popup(
			{
				autoPan: false,
				closeButton: false,
				closeOnClick: true,
				minWidth: 0,
				maxWidth: 0,
				maxHeight: 0,
				className: 'stage-popup',
				interactive: true,
				bubblingMouseEvents: true,

			},
		);

		popup.setContent(
			(
				<div class="wrapper" onClick={( ev ) => this.Stage.activate()}>

					<h3>{Specifications.modeTitle( mode )} {point}</h3>
					<h2>{title}</h2>

					<Specifications type="stage" data={this.Stage.data} mode={this.Stage.data.layer} />
				</div>
			),
		);

		popup._content.addEventListener( 'wheel', ( event ) => {
			if ( event.ctrlKey ) {
				event.preventDefault();
			}
		},
		{
			passive: false,
		} );

		this.bindPopup( popup );
	},
} );

StageMarker.First = StageMarker.extend( {

	generateIcon() {
		return divIcon( {

			className: 'marker-stage marker-stage--first',
			html: this.renderIcon(),
			iconSize: [ 48, 64 ],
			iconAnchor: [ 24, 64 ],
			popupAnchor: [ 39, -50 ],
		} );
	},
} );

StageMarker.Last = Marker.extend( {

	options: {
		icon: false,
		zIndexOffset: 1000,
		clickable: true,
	},

	initialize( latlng, Stage ) {
		this.Stage = Stage;

		const options = {
			...this.options,
			'icon': this.generateIcon(),
		};

		Marker.prototype.initialize.call( this, latlng, options );
	},

	generateIcon() {
		return divIcon( {

			className: 'marker-stage marker-stage--last',
			html: this.renderIcon(),
			iconSize: [ 48, 64 ],
			iconAnchor: [ 24, 64 ],
			popupAnchor: [ 42, -40 ],
		} );
	},

	renderIcon() {
		const element = DomUtil.create( 'div', 'tao-marker' );
		this.gallery = RenderJSX.ref();

		RenderJSX.render(
			<div class="position-relative">
				<div class="pin">{Icomoon.pin.toSvgElement()}</div>
				<div class="flag">{Icomoon.flag3.toSvgElement()}</div>
			</div>,
		).on( element );

		return element;
	},
} );

StageMarker.getMarkers = ( latlngs, Stage ) => {
	const flattened = latlngs.flat( 3 );

	const [ start ] = flattened.slice( 0, 1 );
	const [ end ] = flattened.slice( -1 );

	switch ( Stage.data.typ ) {
		case 'start':
			return new StageMarker.First( start, Stage );

		case 'end':
			return [
				new StageMarker.Last( end, Stage ),
				new StageMarker( start, Stage ),
			];

		default:
			return new StageMarker( start, Stage );
	}
};

export default StageMarker;
