
const TabScrollMixin = {

	getScrollPositionTop( element ) {
		return element.offsetTop - this._content.offsetTop;
	},

	onTabClick( ev ) {
		ev.preventDefault();
		return this.scrollTab( ev.target.hash );
	},

	scrollTab( hash ) {
		const element = this._content.querySelector( hash );
		this._content.querySelector( '#scroll-container' ).scrollTo( 0, this.getScrollPositionTop( element ) );
	},

	scrollSpy() {
		const container = this._content.querySelector( '#scroll-container' );
		if ( ! container ) {
			return;
		}

		let found = false;
		this._content.querySelectorAll( '.scrollable' ).forEach( ( elem ) => {
			const elemTop = this.getScrollPositionTop( elem );

			if ( container.scrollTop >= elemTop || ! found ) {
				found = '#' + elem.id;
			}

			if ( container.scrollTop + container.clientHeight >= container.scrollHeight ) {
				found = '#' + elem.id;
			}
		} );

		// Set found taba ctive.
		this._content.querySelectorAll( '.tabs li' ).forEach( ( tab ) => {
			tab.classList.toggle( 'active', tab.firstChild.hash === found );
		} );
	},

};

export default TabScrollMixin;
