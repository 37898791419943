
import RenderJSX from '../../../Lib/render-jsx';
import Icomoon from '../../../Lib/Icomoon';

import { Control, DomUtil, DomEvent } from 'leaflet';

import OpenAndClose from './mixins/OpenAndClose';
import TabScroll from './mixins/TabScroll';

import { Gallery, Specifications } from '../../Components';

/* global __, _c */

const NavigationControl = Control.extend( {

	includes: [ OpenAndClose, TabScroll ],

	options: {
		'position': 'topright',
		'toggleClass': 'open',
	},

	initialize( Tourguide, options ) {
		this.Tourguide = Tourguide;
		return Control.prototype.initialize.call( this, options );
	},

	/**
	 * HTML BASE STRUCTURE ON ADD
	 *
	 * @param {Map} map
	 */
	onAdd( map ) {
		this.map = map;

		this._container = DomUtil.create( 'div', 'tao-module--navigation no-propagation' );

		DomEvent.disableClickPropagation( this._container );
		DomEvent.disableScrollPropagation( this._container );

		this._container.addEventListener( 'wheel', ( event ) => {
			if ( event.ctrlKey ) {
				event.preventDefault();
			}
		},
		{
			passive: false,
		} );

		this.reset();

		return this._container;
	},

	onRemove() {
		delete this._container;
	},

	reset() {
		if ( this._container ) {
			this._container.innerHTML = '';

			this._stage = DomUtil.create( 'div', 'overlay-stage', this._container );
			this._content = DomUtil.create( 'div', 'overlay-content overlay_content content', this._container );
		}

		//this.headline = false;
		this.stage = false;
		this.has_events = false;
		this._container.classList.remove( 'open' );
		//this.removeClass( 'stage' ).removeClass( 'content' );

		return this;
	},

	async setHeading( layer ) {
		this._stage.innerHTML = '';
		if ( ! layer ) {
			return '';
		}

		const { title, sequence_id: sequence, start: von, end: bis, layer: mode } = layer.data;

		const prev = layer.getPrev ? await layer.getPrev() : false;
		const next = layer.getNext ? await layer.getNext() : false;

		const parent = layer.getParent ? await layer.getParent() : false;

		RenderJSX.render(
			<>
				<div class="stage">
					<h3 class="headline">{title }</h3>

					<div class="infos d-flex align-items-center w-100">
						<div class="counter col-auto">
							{'overview' === mode
								? <div class="text-center d-flex align-items-center justify-content-center">
									<span class="number">{title}</span>
								</div>
								: <>
									<div class="text-center d-flex align-items-center justify-content-center">
										<i class="icon icon-sign">{Icomoon.sign.toSvgElement()}</i>
										<span class="number">{sequence}</span>
									</div>
									<div>{Specifications.modeTitle( mode )}</div>
								</>
							}
						</div>
						<div class="directions col">
							{von ? ( <div class="row m-0"><span class="col-2">{_c( 'location', 'from' )}</span> <span class="col-8 ps-3 ps-sm-1">{von}</span></div> ) : ''}
							{bis ? ( <div class="row m-0"><span class="col-2">{_c( 'location', 'to' )}</span> <span class="col-8 ps-3 ps-sm-1">{bis}</span></div> ) : ''}
						</div>
						{parent ? <div class="icon closer col-auto p-0 text-secondary" onClick={() => parent.activate()}>{Icomoon.close.toSvgElement()}</div> : ''}
					</div>

					<Specifications type="stage" data={layer.data} mode={mode} class={[ 'd-flex', 'flex-nowrap' ]} spanClass="col-auto" />

				</div>
				<div class="arrows">
					{prev ? <div class="arrow prev" onClick={() => prev.activate()}><i class="icon" _content={Icomoon.chevronLeft} /><span>{Specifications.modeTitle( prev.data.layer )} {prev.data.sequence_id}</span></div> : ''}
					{next ? <div class="arrow next" onClick={() => next.activate()}><i class="icon" _content={Icomoon.chevronRight} /><span>{Specifications.modeTitle( next.data.layer )} {next.data.sequence_id}</span></div> : ''}
				</div>
				<div
					class="overlay_toggler d-flex d-md-none"
					onClick={() => this._container.classList.toggle( 'open' )}
				>
					<i class="icon" _content={Icomoon.chevronLeft} />
				</div>
			</>,
		).on( this._stage );

		return this._stage;
	},

	setContent( content ) {
		this._content.innerHTML = '';
		if ( ! content ) {
			DomUtil.removeClass( this._content, 'has-tabs' );
			return '';
		}

		const {
			altitude_profile: altitudeProfile,
			description,
			gallery,
			downloads,
			ground_types: groundTypes,
		} = content.data;

		const tabs = [
			altitudeProfile,
			description,
			gallery,
			downloads,
		].filter( ( i ) => i );

		if ( tabs.length > 0 ) {
			DomUtil.addClass( this._content, 'has-tabs' );
		} else {
			DomUtil.removeClass( this._content, 'has-tabs' );
		}

		RenderJSX.render(
			<>
				<div id="scroll-container" class="content container g-2" onScroll={( ev ) => this.scrollSpy()}>

					{gallery && gallery.length > 0 ? (
						<div id="gallery" class="scrollable mt-0">
							<span class="fullscreen_icon"></span>

							<Gallery id="stagecarousel" images={gallery} withDescription={true} withCopyright={true} />
						</div>
					) : ''}

					{altitudeProfile && altitudeProfile.id ? (

						<div id="hoehenprofil" class="scrollable">
							<h4 class="hl spaces">{__( 'Altitude Profile' )}</h4>
							<div class="w-100 text-center">
								<img class="tour_profile_image" src={altitudeProfile.orig} draggable="false" loading="lazy" />
							</div>
							{groundTypes ? (
								<div class="ground-type">
									<ul>
										{groundTypes.map( ( type ) => (
											<li><span class="stroke {type.name}"></span><span class="name">{type.name}</span><span class="length">{type.length}</span></li>
										) )}
									</ul>
								</div>
							) : ''}
						</div>
					) : ''}

					{description ? (
						<div id="beschreibung" class="scrollable description spaces">
							<div class="description_text" _content={description}></div>
						</div>
					) : ''}

					{downloads ? (
						<div id="downloads" class="scrollable spaces" >
							<h4 class="hl">{__( 'Downloads' )}</h4>
							<p style="margin-bottom:25px;margin-left:4px;">Sie finden hier alle Downloads, damit Sie die Etappe draussen genießen können.</p>
							<ul>
								{downloads.map( ( download ) => (
									<li class="{download.type}"><a href={download.url} download>{download.name || download.type.toUpperCase()}</a></li>

								) )}
							</ul>

						</div>
					) : ''}

				</div>
				<ul class="tabs">

					{altitudeProfile && altitudeProfile.id ? (
						<li><a href="#hoehenprofil" onClick={( ev ) => this.onTabClick( ev )}>{__( 'Altitude Profile' )}</a></li>
					) : ''}

					{description ? (
						<li><a href="#beschreibung" onClick={( ev ) => this.onTabClick( ev )}>{__( 'Description' )}</a></li>
					) : ''}

					{downloads ? (
						<li><a href="#downloads" onClick={( ev ) => this.onTabClick( ev )}>{__( 'Downloads' )}</a></li>
					) : ''}
				</ul>
			</>,
		).on( this._content );

		this.scrollSpy();
		this.process( this._content );

		return this._content;
	},

	setPoiContent( content ) {
		this._content.innerHTML = '';
		if ( ! content ) {
			return '';
		}

		const {
			title,
			description,
			gallery,
		} = content.data;

		const icon = this.Tourguide.getIcon( content.data.category.icon );
		const active = this.Tourguide.getModule( 'Stages' ).getActive();

		RenderJSX.render(
			<>
				<div class="poi-headline d-flex align-items-center justify-content-between">
					<div class="d-flex align-items-center">
						<img class="poi-icon" src={icon}></img>
						<h2 class="m-0">
							<span class="poi-title">{title}</span>
						</h2>
					</div>
					{! active.is( 'stage' )
						? <div
							class="icon closer p-0 text-white"
							onClick={() => this.reset()}
						>
							{Icomoon.close.toSvgElement()}
						</div> : ''
					}
				</div>
				<div id="scroll-container" class="content container g-2">

					{gallery && gallery.length > 0 ? (
						<div id="gallery" class="scrollable">
							<Gallery id="stagecarousel" images={gallery} withDescription={true} withCopyright={true} />
						</div>
					) : ''}

					{description ? (
						<div id="beschreibung" class="scrollable description spaces">
							<div class="description_text" _content={description}></div>
						</div>
					) : ''}
				</div>
			</>,
		).on( this._content );

		this.process( this._content );
		return this._content;
	},

	process( container ) {
		if ( container.querySelector( '.instagram-media' ) ) {
			if ( typeof window.instgrm === 'undefined' ) {
				const scrpt = document.createElement( 'script' );
				scrpt.async = true;
				scrpt.src = 'https://platform.instagram.com/en_US/embeds.js';

				document.body.appendChild( scrpt );
			} else {
				window.instgrm.Embeds.process();
			}
		}
	},
} );

export default NavigationControl;
