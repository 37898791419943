
import { Polyline } from 'leaflet';

const StageLine = Polyline.extend( {

	initialize( latlngs, Stage ) {
		const options = {
			...this.getStyleForStatus( Stage?.data?.options?.status || 'normal' ),
			...Stage?.data?.options,
			className: 'stage ',
		};

		this.Stage = Stage;

		return Polyline.prototype.initialize.call( this, latlngs, options );
	},

	onAdd( map ) {
		return Polyline.prototype.onAdd.call( this, map );
	},

	getStyleForStatus( status ) {
		const styles = getComputedStyle( document.body );
		const options = {
			color: ( styles.getPropertyValue( '--tao-stage-color-' + status ) || '' ).trim(),
			weight: parseInt( styles.getPropertyValue( '--tao-stage-weight-' + status ) ),
		};

		return options;
	},

	setStatus( newStyle ) {
		if ( this.Stage.active ) {
			newStyle = 'active';
		}

		const options = this.getStyleForStatus( newStyle );

		this.setStyle( options );
		this.redraw();
	},

} );

export default StageLine;
