import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { GestureHandling } from	'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

Leaflet.Map.addInitHook( 'addHandler', 'gestureHandling', GestureHandling );

window.L = window.L || Leaflet;
export default Leaflet;
