/**
 * @see https://loreanvictor.github.io/render-jsx/
 */

import { CommonDOMRenderer } from 'render-jsx/dom';
import { ref } from 'render-jsx/common';

const RenderJSX = new CommonDOMRenderer();

RenderJSX.ref = ref;

export default RenderJSX;
