
import OSM from './osm.js';
import Custom from './custom.js';
import Mapbox from './mapbox.js';
import Google from './google.js';

const TileLayers = {
	'osm': OSM,
	'custom': Custom,
	'google': Google,
	'mapbox': Mapbox,
};

class TileLayerHandler {
	static hasProvider( provider ) {
		return TileLayers.hasOwnProperty( provider );
	}

	static getTileLayer = async ( provider, map, options ) => {
		return await TileLayers[ provider ]( map, options );
	};
}

export default TileLayerHandler;
