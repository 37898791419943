
import * as Svgs from './icons';

const Parser = new DOMParser();

class IcomoonIcon {
	constructor( svg ) {
		this.svg = svg;
	}

	toString() {
		return this.svg;
	}

	toSvgElement() {
		const doc = Parser.parseFromString( this.svg, 'image/svg+xml' );
		return doc.firstChild;
	}
}

const Icons = {};
for ( const name in Svgs ) {
	Icons[ name ] = new IcomoonIcon( Svgs[ name ] );
}

window.Icomoon = Icons;
export default Icons;
