
import RenderJSX from '../../../Lib/render-jsx';
import Icomoon from '../../../Lib/Icomoon';

import { Control, DomUtil, DomEvent } from 'leaflet';

import { Gallery } from '../../Components';

const NavigationControl = Control.extend( {

	options: {
		'position': 'topleft',
	},

	initialize( Tourguide, options ) {
		this.Tourguide = Tourguide;
		return Control.prototype.initialize.call( this, options );
	},

	/**
	 * HTML BASE STRUCTURE ON ADD
	 *
	 * @param {Map} map
	 */
	onAdd( map ) {
		this.map = map;

		this._container = DomUtil.create( 'div', 'tao-module--fullscreen no-propagation' );
		this._container.tabIndex = -1;

		DomEvent.disableClickPropagation( this._container );
		DomEvent.disableScrollPropagation( this._container );

		DomEvent.on( this._container, 'keydown', this.handleKeyDown, this );

		return this._container;
	},

	onRemove() {
		this.hide();
		delete this._container;
	},

	show() {
		DomUtil.addClass( this._container, 'open' );
		this.map.keyboard.disable();

		this.focus();

		this.map.fire( 'open.fullscreen', { layer: this } );
	},

	hide() {
		DomUtil.removeClass( this._container, 'open' );
		this.map.keyboard.enable();

		this.blur();

		this.reset();
		this.map.fire( 'close.fullscreen', { layer: this } );
	},

	focus() {
		return this._container.focus();
	},

	blur() {
		return this._container.blur();
	},

	handleKeyDown( ev ) {
		switch ( ev.keyCode ) {
			case 27:
				ev.stopPropagation();
				this.hide();
				return;
		}

		if ( this.gallery._resolved ) {
			this.gallery.$.getCarousel()._keydown( ev );
		}
	},

	render( gallery ) {
		this.gallery = RenderJSX.ref();

		RenderJSX.render(
			<div id="fullscreen-gallery" class="content w-100 h-100 d-flex justify-content-center align-items-center" >
				<div class="icon icon-close" onClick={() => this.hide()} _content={Icomoon.close}></div>

				<Gallery id="fullscreen-carousel" images={gallery} _ref={this.gallery} data-bs-interval="0" imageArgs={{ 'full': 1 }} withCopyright={true} />
			</div>,
		).on( this._container );

		return this._container;
	},

	reset() {
		RenderJSX.remove( this._container.firstChild );
	},
} );

export default NavigationControl;
