
import Control from './Control';

import Module from './../Module';

import './navigation.scss';

export default class Navigation extends Module {
	/**
	 * Initialise Marker Element and add to map.
	 */
	onAdd = () => {
		// Add Control.
		this.Control = new Control( this.Tourguide, { 'position': 'topright' } );
		this.Control.addTo( this.Tourguide.getMap() );

		// Add Events.
		this.Tourguide.getMap().on( 'activate.tao', this.onActivate, this );
		this.Tourguide.getMap().on( 'poi-activate.tao', this.onPoiActivate, this );
		this.Tourguide.getMap().on( 'deactivate.tao', this.onDeactivate, this );
	};

	/**
	 * Remove Marker Element from map.
	 */
	onRemove = () => {
		// Remove Events.
		this.Tourguide.getMap().off( 'activate.tao', this.onActivate, this );
		this.Tourguide.getMap().off( 'poi-activate.tao', this.onPoiActivate, this );
		this.Tourguide.getMap().off( 'deactivate.tao', this.onDeactivate, this );

		// Remove Control.
		this.Control.remove( this.Tourguide.getMap() );
		delete this.Control;
	};

	onActivate = ( { layer } ) => {
		if ( ! layer.children || layer.children.length <= 0 ) {
			this.Control.show().then( () => {
				this.Control.setHeading( layer );
				this.Control.close().then( () => {
					this.Control.setContent( layer );
					this.Control.open();
				} );
			} );
		} else {
			this.onDeactivate();
		}
	};

	onPoiActivate = ( { layer } ) => {
		this.Control.show().then( () => {
			this.Control.close().then( () => {
				this.Control.setPoiContent( layer );
				this.Control.open();
			} );
		} );
	};

	onDeactivate = () => {
		this.Control.close().then( () => {
			this.Control.setHeading( false );
			this.Control.setContent( false );
		} );
	};
}

