
import RenderJSX from '../../../Lib/render-jsx';

import { Control, DomUtil, DomEvent } from 'leaflet';

import Icomoon from '../../../Lib/Icomoon';

/* global __ */

const NavigationControl = Control.extend( {

	options: {
		'position': 'bottomleft',
	},

	initialize( Tourguide, options ) {
		this.Tourguide = Tourguide;
		return Control.prototype.initialize.call( this, options );
	},

	/**
	 * HTML BASE STRUCTURE ON ADD
	 *
	 * @param {Map} map
	 */
	onAdd( map ) {
		this.map = map;

		this._container = DomUtil.create( 'div', 'tao-module--zoom d-none' );
		this._container.tabIndex = -1;

		DomEvent.disableClickPropagation( this._container );
		DomEvent.disableScrollPropagation( this._container );

		this._container.addEventListener( 'wheel', ( event ) => {
			if ( event.ctrlKey ) {
				event.preventDefault();
			}
		},
		{
			passive: false,
		} );

		this.render();

		return this._container;
	},

	onRemove() {
		this.hide();
		delete this._container;
	},

	show() {
		DomUtil.removeClass( this._container, 'd-none' );
	},

	hide() {
		DomUtil.addClass( this._container, 'd-none' );
	},

	render() {
		RenderJSX.render(
			<div class="inner">
				<div class="icon-plus" title={__( 'ZoomIn' )} onClick={() => this.Tourguide.getMap().zoomIn()}>{Icomoon.faPlus.toSvgElement()}</div>
				<div class="icon-minus" title={__( 'ZoomOut' )} onClick={() => this.Tourguide.getMap().zoomOut()}>{Icomoon.faMinus.toSvgElement()}</div>
				{this.Tourguide.hasModule( 'Stages' ) ? <div class="icon-center" title={__( 'Center' )} onClick={() => this.Tourguide.getModule( 'Stages' ).getActive()?.fitBounds()}>{Icomoon.faCircle.toSvgElement()}</div> : ''}
			</div>,
		).on( this._container );

		return this._container;
	},

	reset() {
		RenderJSX.remove( this._container.firstChild );
	},
} );

export default NavigationControl;
