
export default class TourguideAPI {
	fetchSingle = async ( path, args = {} ) => {
		const { ...options } = args;

		const DOMAIN = process.env.DOMAIN || '';
		return fetch( DOMAIN + path, {
			'method': 'GET',
			'mode': 'cors',
			'redirect': 'follow',
			'headers': {
				'accept': 'application/json',
				...options.headers,
			},
			...options,
		} )
			.then( ( res ) => res.json() );
	};

	fetchMulti = ( path, args = {} ) => {
		return this.fetchSingle( path, args = {} )
			.then( async ( res ) => {
				if ( res.next ) {
					return [
						...res.results,
						...this.fetchMulti( res.next, args ),
					];
				}

				return res.results;
			} );
	};

	getLayer = async ( mode, slug ) => {
		switch ( mode ) {
			case 'country':
				return await this.getCountry( slug );
			case 'segment':
				return await this.getSegment( slug );
			case 'stage':
				return await this.getStage( slug );
			case 'poi':
				return await this.getPoi( slug );
		}

		throw 'invalid mode';
	};

	getCountry = ( slug ) => {
		return this.fetchSingle( `/tour/layer-country/${ slug }/` );
	};

	getSegment = ( slug ) => {
		return this.fetchSingle( `/tour/layer-segment/${ slug }/` );
	};

	getStage = ( slug ) => {
		return this.fetchSingle( `/tour/layer-stage/${ slug }/` );
	};

	getPoi = ( slug ) => {
		return this.fetchSingle( `/tour/poi/${ slug }/` );
	};

	getLogo = ( slug ) => {
		return this.fetchSingle( `/tour/logo/${ slug }/` );
	};

	getOverview = () => {
		return this.fetchMulti( '/tour/layer-overview/' )
			.then( ( res ) => res[ 0 ] );
	};

	getCountries = () => {
		return this.fetchMulti( '/tour/layer-country/' );
	};

	getSegments = () => {
		return this.fetchMulti( '/tour/layer-segment/' );
	};

	getStages = () => {
		return this.fetchMulti( '/tour/layer-stage/' );
	};

	getPois = ( args = {} ) => {
		return this.fetchMulti( '/tour/poi/', args );
	};

	getLogos = ( args = {} ) => {
		return this.fetchMulti( '/tour/logo/', args );
	};
}
